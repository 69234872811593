

@media(min-width: 800px){
  .success {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    padding-bottom: 30px;
  }
  .success img {
    height: 50vh;
    object-fit: contain;
    margin: 0px auto;
  }
}

@media(max-width: 799px){
  .success {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .success img {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    object-fit: contain;
    margin: 0px auto;
  }
}
.success h1 {
  font-family: CooperBT;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.success h2 {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2a2a2a;
}


.success picture {
  padding-top: 10%;
  text-align: center;
  padding-bottom: 10%;
}

