@media (max-width: 850px) {
  .MobileContainer {
    padding-top: 20px;
    padding-bottom: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .DeskContainer {
    display: none;
  }

  .SectionTitle {
    font-family: CooperBT;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
  }

  .ImageContainer {
    max-width: 100%;
    padding-bottom: 30px;
  }
}

@media (min-width: 851px) {
  .MobileContainer {
    display: none;
  }

  .DeskContainer {
    width: 100%;
    height: 600px;
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .TIContainer {
    padding-left: 45px;
    text-align: left;
  }

  .SectionTitle {
    font-family: CooperBT;
    font-size: 30px;
    font-weight: 300;
  }

  .ImageContainer {
    max-width: 660px;
  }
}

.Image {
  width: 100%;
}

.InfoContainer {
  display: flex;
  flex-direction: column;
}

.Text {
  font-family: ModernEra;
  font-size: 16px;
}

.Items {
  line-height: 25px;
  font-family: ModernEra;
  font-size: 16px;
}

@media (max-width: 700px) {
  .ButtonContainer {
    display: none;
  }
}
