.Hero{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.HeroImage{
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    
}


.HeroBox{
    border-bottom-left-radius: 30px;
    width: 95%;
    max-width: 1200px;
}

.HeroImage img{
    width: 100%;
}

.HeroImage iframe{
    border-bottom-left-radius: 30px;
}

.HeroInfo{
    display: flex;
    align-items: center;
    box-sizing: border-box;
}


@media (min-width: 1000px){
    .Hero{
        flex-direction: row-reverse;   
    }

    .HeroInfo{
        width: 37%;
        text-align: left;
        padding-left: 10%;
        padding-right: 15px;
    }

    .HeroImage{
        width: 60%;
    }

    .HeroInfo.Small{
        width: 45%;
        padding-top: 40px;
    }

    .HeroImage.Small{
        width: 55%;
    }
    
    .HeroBox{
        width: 100%;    
    }
}