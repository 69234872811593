.CenterContainer {
  margin: 0 auto;
  width: 90%;
}

.CenterContainer.Mobile {
  width: 100%;
}

.CenterContainer.Flex {
  display: flex;
}

.KitContainer {
  margin: 0 auto;
  width: 90%;
  max-width: 1200px;
}

.GuaranteeContainer {
  margin: 0 auto;
  width: 90%;
  max-width: 835px;
}

.CarouselContainer {
  width: 97.5%;
  max-width: 900px;
  margin-top: 100px;
}

.BrandContainer {
  margin: 0 auto;
  width: 90%;
  max-width: 728px;
  margin-top: 100px;
}

.PricingContainer {
  position: relative;
  margin: 0 auto;
  width: 90%;
  max-width: 750px;
  margin-top: 70px;
}

.ProcessContainer {
  width: 90%;
  margin: 0 auto;
  max-width: 1100px;
}

.AlignersContainer {
  width: 90%;
  margin: 0 auto;
  max-width: 622px;
}

.ExplainedContainer {
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
}

.VideoContainer {
  width: 100%;
  max-width: 1000px;
}

.AppContainer {
  margin-top: 120px;
}

.ApolloBackground {
  display: none;
  position: absolute;
  top: 400px;
  width: 200px;
  height: 2182px;
  background-color: black;
  background-size: 100% auto;
  z-index: -1;
}

.HeroTitle {
  font-family: "CooperBT";
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  color: #252525;
  line-height: 1.2em;
  margin: 0;
  margin-top: 15px;
}

.HeroTitle.Small {
  font-size: 35px;
}

.HeroDesc {
  font-family: "ModernEra";
  font-size: 16px;
  color: #6d7278;
  font-weight: normal;
  text-align: center;
}

.HeroDescBold {
  font-family: "ModernEra";
  font-size: 16px;
  color: black;
  font-weight: bold;
  text-align: center;
}

.HeroSubtitle {
  font-family: "ModernEra";
  font-size: 36px;
  color: #99bffc;
  font-weight: bold;
  text-align: center;
}

.ButtonContainer {
  text-align: center;
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
}

.Relative {
  position: relative;
}

.MoonsStepsContainer {
  margin: 0 auto;
  width: 90%;
}

.HideMobile {
  display: none;
}

.HideDesktop {
  display: block;
}

.MarginContainer {
  margin-top: 100px;
}

.CenterFlex {
  width: 100%;
  display: flex;
  justify-content: center;
}

.CompleteDesc {
  width: 100%;
}

@media (min-width: 768px) {
  .ApoloBackground2 {
    display: none;
  }

  .HeroTitle.Small {
    font-size: 45px;
  }

  .HeroTitle {
    font-size: 45px;
  }
}

@media (min-width: 850px) {
  .MarginContainer {
    margin-top: 120px;
  }

  .Wave2 {
    display: block;
  }
  .ApoloBackground2 {
    background-size: 100% auto;
    transform: scaleX(1);
  }

  .VideoContainer {
    width: 90%;
  }

  .CarouselContainer {
    margin: 120px auto;
  }

  .CenterContainer,
  .CenterContainer.Mobile {
    width: 90%;
  }

  .BrandContainer {
    margin-top: 50px;
  }

  .ApolloBackground {
    display: block;
  }
}

@media (min-width: 1000px) {
  .HeroDesc {
    text-align: left;
  }

  .HeroDescBold {
    text-align: left;
  }

  .HeroSubtitle {
    text-align: left;
  }

  .ButtonContainer {
    text-align: left;
  }

  .HeroTitle {
    text-align: left;
    margin-top: 0px;
  }
}

@media (min-width: 900px) {
  .ApoloBackground2 {
    display: block;
  }

  .HideMobile {
    display: block;
  }
  .HideDesktop {
    display: none;
  }
  .CenterContainer,
  .CenterContainer.Mobile {
    width: 90%;
  }

  .GuaranteeContainer {
    max-width: 835px;
  }
}

@media (min-width: 1100px) {
  .CenterContainer,
  .CenterContainer.Mobile {
    width: 80%;
  }

  .AlignersContainer {
    max-width: 1200px;
  }
}

.DisplayNone {
  display: none;
}
