.SectionTitle{
    font-family: 'CooperBT';
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    color: #252525;
    margin: 50px 9px 0 10px;
}

.DescriptionTitle{
    font-family: 'CooperBT';
    font-size: 16px;
    text-align: center;
    color: #6d7278;
    margin: 5px 5%;
}