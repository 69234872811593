.TabletContainer{
    display: none;
}

.CarouselTablet {
    display: flex;
    height: 100%;
    align-self: center;
    margin: 0 80px;
    width: 100%;
    padding-bottom: 5px;
    overflow: hidden;
    position: relative;
}

.SlideshowContainerTable {
    position: relative;
    display: flex;
    left: 0;
}

.SliderMoveTablet {
    width: 150%;
    display: flex;
}

.StepContainerImagesTable{
    width: 100%;
    display: flex;
    padding: 0 2% 0 2%;
}
.CarouselImage{
    align-self: center;
    width: 100%;
}
.PhotoTablet{
    flex-direction: column;
    width: 30%;
    align-self: center;
    border-radius: 65px;
    float: left;
    display: inline-flex;
    margin: 0 20px 8px 20px;
    align-self: flex-start;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.SliderActive {
    border: none;
    width: 10px;
    height: 10px;
    background-color: #99bffc;
    border-radius: 5px;
    opacity: .5;
    z-index: 100;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
}

.SliderBootOut {
    margin-top: 3%;
    z-index: 1;
    height: 20px;
    text-align: center;
}

.SliderBotton {
    border: none;
    transition: opacity .25s;
    width: 6px;
    height: 6px;
    background-color: #d8d8d8;
    border-radius: 3px;
    opacity: .5;
    z-index: 100;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
}

.Wrapper{
    overflow: hidden;
}


@media (min-width: 700px) and (max-width: 1039px) {
    .TabletContainer {
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-self: center;
    }
}