.ContainerText {
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
    height: 130px;
}

.CarrouselTitle {
    display:flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.35;
    text-align: center;
    color: #252525;
}

.CarrouselDescription {
    display:flex;
    flex-direction: column;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
    color: #6d7278;
}