.Guarantee {
  box-sizing: border-box;
  width: 100%;
  border-radius: 62px;
  box-shadow: 10px 10px 30px 0 rgba(0,0,0,.2);
  background: linear-gradient(to bottom,#8dc3fb,#dbc2fb);
  padding: 4px;
}

.GuaranteeContent {
  border-radius: 62px;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px 10px;
  box-sizing: border-box;
  text-align: center;
}

.GuaranteeTitle {
  font-family: "CooperBT";
  font-size: 30px;
  font-weight: 300;
  color: #252525;
  text-align: center;
}

.GuaranteeDesc {
  font-family: "ModernEra";
  font-size: 16px;
  color: #1b1b1b;
  margin-top: 15px;
}

.GuaranteeLink {
  color: #1b1b1b;
  margin-top: 20px;
}

.GuaranteeLink a {
  text-decoration: none;
  font-weight: bold;
  color: #0752f9;
}

@media (min-width: 850px) {
  .GuaranteeContent {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    padding: 60px 55px;
  }
  .GuaranteeTitle {
    text-align: left;
  }

  .Seal {
    text-align: left;
    margin-right: 50px;
  }

  .Guarantee {
    background: linear-gradient(to right,#8dc3fb,#dbc2fb);
  }
}
