.ContainerAdvantage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ContentAdvantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 10px 10px 10px 0 #dfe9f7;
    background-color: #e9f2fe;
    border-radius: 20px;
    width: 75%;
    padding: 45px 0 5px 0;
}

.Items {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 1.35;
    color: #252525;
    font-weight: bold;
    font-family: 'ModernEra';
}

.Description {
    margin: 30px 0 40px 0;
}

@media (min-width: 700px)  {
    .ContentAdvantage {
        flex-direction: row;
        justify-content: space-around;
        width: 92%;
        max-width: 1152px;
    }
}