.Container {
  width: 100%;
  height: auto;
  background-color: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px 0px;
}

.LinksContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Link {
  text-decoration: none;
  color: white;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 25px;
}

.SocialNetwork {
  padding: 10px 15px 30px 15px;
}

.Text {
  width: 100%;
  font-family: "ModernEraMono";
  font-size: 15px;
  color: #525252;
  text-align: center;
}

@media (min-width: 651px) {
  .LinksContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
