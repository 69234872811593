.CarouselMovil {
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.SlideshowContainer {
    position: relative;
    display: flex;
    flex-direction: column;
}

.SliderMove {
    display: flex;
    margin-left: 6%;
}

.StepContainerImages {
    align-self: center;
    justify-content: center;
    border-radius: 75px;
    margin-left: 2%;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.SliderActive {
    border: none;
    width: 10px;
    height: 10px;
    background-color: #99bffc;
    border-radius: 5px;
    opacity: .5;
    z-index: 100;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
}

.SliderBootOut {
    position: static;
    align-self: center;
    bottom: 5px;
    margin-top: 10px;
    z-index: 1;
    height: 20px;
    text-align: center;
}

.SliderBotton {
    border: none;
    transition: opacity .25s;
    width: 6px;
    height: 6px;
    background-color: #d8d8d8;
    border-radius: 3px;
    opacity: .5;
    z-index: 100;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
}

.ImageSlider { 
    border-radius: 20px 20px 0 0;
    width: 270px;
}

.ImageSlider2 {
   border-radius: 0 0 20px 20px;
   width: 270px;
}

@media (min-width: 370px) and (max-width: 471px) {
    .ImageSlider, .ImageSlider2 {
        width: 314px;
    }
}

@media (min-width: 471px) and (max-width: 575px) {
    .ImageSlider, .ImageSlider2{
        width: 380px;
    }
}

@media (min-width: 575px) and (max-width: 700px) {
    .ImageSlider, .ImageSlider2 {
        width: 480px;
    }
}

@media (min-width: 700px) {
    .CarouselMovil {
        display: none;
    }
}