.footerFix {
  display: block;
  height: 10vh;
  position: fixed;
  bottom: 0px;
}

.fatherContainer{
  display: block;
  position: relative;
  padding-bottom: 35px;
  height: inherit;
}

@media(min-width: 800px){

  .ContainerFlex {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-shadow : inset 0px 3px 5px -3px rgba(0, 0, 0, 0.3);
  }
  .checkoutImage {
    width: 100%;
    padding-left: 8%;
    padding-right: 5%;
    height: 65vh;
    object-fit: contain;
    border-radius: 0 0 30px 30px;
  }
  .col1 {
    display: flex;
    width: 50%;
    order: 1;
    flex-direction: column;
  }
  .col2 {
    display: flex;
    width: 50%;
    order: 2;
    flex-direction: column;
  }
  .productInformation{
    display: flex;
    margin-left: 35%;
    margin-right: 20%;
    flex-direction: column;
  }
  .FooterIcons{
    display: flex;
    margin-top: 3%;
    margin-left: 35%;
    margin-right: 25%;
    align-content: center;
    flex-direction: column;
  }
  
}
/**
@media(min-height:200px) and (max-height:499px){
  .fatherContainer{
    display: block;
    position: relative;
  }
}

@media(max-height:500px) and (min-width:800px){
  .fatherContainer{
    display: block;
    position: relative;
  }
}
@media(min-height:501px) and ( max-height:700px) and (min-width:800px){
  .fatherContainer{
    display: block;
    position: relative;
  }
}

@media(min-height:701px){
  .fatherContainer{
    display: block;
    position: relative;
  }
}
*/
@media(max-width:499px){
  .ContainerFlex {
    display: flex;
    flex-direction: column;
    box-shadow : inset 0px 3px 6px -3px rgba(0, 0, 0, 0.3)

  }
  .checkoutImage {
    width: 95%;
    margin-left: 5%;
    height: auto;
    object-fit: contain;
  }
  .col1 {

    width: 100%;
    height: 100%;
    order: 1;
  }
  .col2 {

    width: 100%;
    height: 100%;
    order: 2;
  }
  .productInformation{
    margin-left: 5%;
    margin-right: 15%;
    align-items: center;
    height: 30%;
  }
  .FooterIcons{
    display: flex;
    margin-top: 5%;
    margin-left: 30%;
    margin-right: 5%;
    align-content: center;
    flex-direction: column;
  }

  .productInformation ul {
    padding-inline-start: 10%;
  }
  .footerFix {
    display: block;
    height: 10vh;
  }
}
@media (min-width:500px) and (max-width:799px){
  .ContainerFlex {
    display: flex;
    flex-direction: column;
    box-shadow : inset 0px 3px 6px -3px rgba(0, 0, 0, 0.3)

  }
  .checkoutImage {
    width: 95%;
    margin-left: 5%;
    height: auto;
    object-fit: contain;
  }
  .col1 {

    width: 100%;
    height: 100%;
    order: 1;
  }
  .col2 {

    width: 100%;
    height: 100%;
    order: 2;
  }
  .productInformation{
    margin-left: 5%;
    margin-right: 15%;
    align-items: center;
    height: 30%;
  }
  .FooterIcons{
    display: flex;
    margin-top: 5%;
    margin-left: 40%;
    margin-right: 15%;
    align-content: center;
    flex-direction: column;
  }

  .productInformation ul {
    padding-inline-start: 10%;
  }
  .footerFix {
    display: block;
    height: 10vh;
  }
}


.titleInformation{
  font-family: CooperBT;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #252525;

}

.subtitleInformation{
  font-family: ModernEra;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
  margin-bottom: -2%;
}
li{
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #2a2a2a;
}

.background {
  background-color: #ffffff;
}



.FooterIcons span {
  font-family: ModernEra;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9da3ab;
  align-content: center;
}
.iconsMargin{
  margin-bottom: 10px;
}
.icon{
  width: 16px;
  height: 18px;
  transform: translateY(5px);
}

.truck {
  width: 21px;
  height: 14px;
  object-fit: contain;
  transform: translateY(5px);

}
