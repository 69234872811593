.CarouselDesktop {
    display: flex;
    height: 100%;
    align-self: center;
    margin: 0 80px;
    width: 100%;
    padding-bottom: 5px;
    overflow: hidden;
    position: relative;
}

.DesktopContainer{
    display: none;
}

.SlideShowContainerDesktop{
    position: relative;
    display: flex;
    left: 0;
}

.PhotoDesktop {
    width: 300%;
    align-self: center;
    border-radius: 70px;
    margin: 0 30px 8px 30px;
    align-self: flex-start;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.PhotoDesktopThree{
    margin: 0 30px 8px 30px;
}

.CarouselImage{
    align-self: center;
    width: 100%;
}

.Wrapper{
    overflow: hidden;
}

@media (min-width: 1040px) {
    .DesktopContainer{
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-self: center;
    }
}