.Carrousel {
  margin: 50px 0;
}

.Wrapper {
  display: flex;
  flex-direction: column;
}

.BackgroundWaves {
  width: 100%;
  padding-top: 100px;
  position: relative;
  object-position: 50% 50%;
}

.RectangleBackground {
  position: absolute;
  left: 0;
  padding-top: 150px;
  width: 100%;
  background-size: 100% auto;
  z-index: -1;
  height: 350px;
}

@media (min-width: 700px) {
  .Wrapper {
    padding: 0 5%;
  }

  .ImageSmall {
    display: none;
  }

  .FixDinamic {
    display: none;
  }

  .Fix {
    display: none;
  }

  .ImageBig {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 466px;
    bottom: 0;
    object-position: 0 157px;
  }

  .RectangleBackground {
    position: absolute;
    padding-top: 0px;
    left: 0;
    width: 100%;
    background-size: 100% auto;
    z-index: -1;
  }
}

@media (max-width: 700px) {
  .ImageBig {
    display: none;
  }

  .ImageSmall {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 515px;
    bottom: 0;
    object-position: 0 130px;
  }

  .FixButton {
    display: none;
  }

  .Container {
    position: relative;
  }

  .Fix {
    position: fixed;
    bottom: 10px;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
  }

  .FixDinamic {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
